import React, { useRef } from "react";
import { Modal, Radio } from "antd";
import { ValidateInput } from "./ValidateBannerConfig";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { addNewBlog } from "../../../services/Blogs";
import swal from "sweetalert";
import { actions as blogsActions } from "../../../store/reducers/BannerReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";
import { addNewBanner } from "../../../services/BannerConfig";

export default function AddNewBlog({
  userModal,
  setAddUserModal,
  _getBlogList,
  domainName,
  setDomainName,
}) {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const fileRef = useRef();
  const fileRef1 = useRef();

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      formData.set("image", values.banner_image);
      formData.set("mobile_image", values.mobile_image);
      formData.set("content", values.banner_content);
      formData.set("domain", values.domain);

      let response = await addNewBanner(formData);
      console.log("Response roles", response);
      setAddUserModal();
      await ResetForm();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });

      await setSubmitting(false);
      if (domainName === values.domain) {
        await dispatch(
          blogsActions.onRequest({
            domain: values.domain,
          })
        );
      } else {
        await setDomainName(values.domain);
      }

      // await setAddUserModal();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  const ResetForm = () => {
    formikRef?.current?.resetForm({
      blog_title: "",
      blog_url: "",
      blog_description: "",
      blog_image: "",
      blog_short_description: "",
      blog_status: "",
      slug: "",
      domain: "",
    });
    fileRef.current.value = "";
    fileRef1.current.value = "";
  };
  return (
    <div>
      {" "}
      <Modal
        open={userModal}
        onCancel={() => {
          setAddUserModal();
          ResetForm();
        }}
        footer={false}
        width={700}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                banner_image: "",
                banner_content: "",
                mobile_image: "",
                domain: "",
              }}
              innerRef={formikRef}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Add New Banner</h4>
                      {/* <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button> */}
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Banner Image
                            </label>
                            <div className="contact-name">
                              <input
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                ref={fileRef}
                                // id="formFile"
                                // value={values.blog_image}
                                onChange={(e) => {
                                  setFieldValue(
                                    "banner_image",
                                    e.currentTarget.files[0]
                                  );
                                }}
                                onBlur={handleBlur}
                                placeholder="Choose image"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.banner_image}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Banner Mobile Image
                            </label>
                            <div className="contact-name">
                              <input
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                ref={fileRef1}
                                // id="formFile"
                                // value={values.mobile_image}
                                onChange={(e) => {
                                  setFieldValue(
                                    "mobile_image",
                                    e.currentTarget.files[0]
                                  );
                                }}
                                onBlur={handleBlur}
                                placeholder="Choose image"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.mobile_image}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Domain
                            </label>
                            <div className="contact-name">
                              <Radio.Group
                                onChange={(e) => {
                                  setFieldValue("domain", e.target.value);
                                }}
                                value={values?.domain}
                              >
                                <Radio value={"chu"}>CHU</Radio>
                                <Radio value={"saudiVisa"}>Saudi Visa</Radio>
                              </Radio.Group>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.domain}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Banner Content
                            </label>
                            <div className="contact-name">
                              <Editor
                                apiKey={TINY_MCE_API_KEY}
                                // initialValue="<p>This is the initial content of the editor</p>"
                                init={TINY_MCE_EDITOR}
                                // init={{
                                //   height: 300,
                                //   menubar: false,
                                //   plugins: [
                                //     "advlist autolink lists link image code charmap print preview anchor",
                                //     "searchreplace visualblocks code fullscreen",
                                //     "insertdatetime media table paste code help wordcount",
                                //   ],
                                //   toolbar:
                                //     "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                                //     "bullist numlist outdent indent | removeformat | help ",
                                //   content_style: "body { color: #828282 }",
                                // }}
                                value={values.banner_content}
                                onEditorChange={(e) => {
                                  setFieldValue("banner_content", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.banner_content}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Adding..." : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setAddUserModal();
                          ResetForm();
                        }}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
