/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateBannerConfig";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";
import { actions as blogsActions } from "../../../store/reducers/BannerReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { Image, Radio } from "antd";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";
import { Modal } from "antd";
import { updateBanner } from "../../../services/BannerConfig";

export default function EditBlog({
  userModal,
  setAddUserModal,
  editedData,
  domainName,
  setDomainName
}) {
  console.log("🚀 ~ file: EditBlog.js:16 ~ EditBlog ~ editedData:", editedData);
  const dispatch = useDispatch();
  const [displayedImage, setDisplayImage] = useState(null);
  const [displayedImage1, setDisplayImage1] = useState(null);

  const [editedData1, setEditedData] = useState({});

  useEffect(() => {
    if (editedData) {
      setEditedData(editedData);
    }
  }, [editedData]);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      if (values.banner_image_updated) {
        formData.set("image", values.banner_image_updated);
      } else {
        formData.set("image", values.banner_image);
      }
      if (values.mobile_image_updated) {
        formData.set("mobile_image", values.mobile_image_updated);
      } else {
        formData.set("mobile_image", values.mobile_image);
      }
      // formData.set("image", values.blog_image);
      formData.set("content", values.banner_content);
      formData.set("domain", values.domain);

      let response = await updateBanner(formData, editedData.id);

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      // await dispatch(blogsActions.onRequest({ domain: domainName }));
      if (domainName === values.domain) {
        await dispatch(
          blogsActions.onRequest({
            domain: values.domain,
          })
        );
      } else {
        await setDomainName(values.domain);
      }
      await setSubmitting(false);
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  console.log(
    "🚀 ~ file: EditBlog.js:45 ~ EditBlog ~ editedData1:",
    editedData1
  );
  return (
    <div>
      <Modal
        // className="fade bd-example-modal-lg"
        // size="lg"
        // show={userModal}
        // onHide={setAddUserModal}
        open={userModal}
        onCancel={setAddUserModal}
        footer={false}
        width={700}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                ...editedData,
                banner_image_updated: null,
                mobile_image_updated: null,
              }}
              enableReinitialize
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Update New Blog</h4>
                      {/* <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button> */}
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="row">
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Banner Image
                              </label>
                              <div className="contact-name">
                                <input
                                  className="form-control"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // id="formFile"
                                  // value={values.blog_image}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "banner_image_updated",
                                      e.currentTarget.files[0]
                                    );
                                    setDisplayImage(
                                      URL.createObjectURL(
                                        e.currentTarget.files[0]
                                      )
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Choose image"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.banner_image}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <Image
                                width={200}
                                src={
                                  displayedImage
                                    ? displayedImage
                                    : values.banner_image
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Banner Mobile Image
                              </label>
                              <div className="contact-name">
                                <input
                                  className="form-control"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // id="formFile"
                                  // value={values.blog_image}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "mobile_image",
                                      e.currentTarget.files[0]
                                    );
                                    setDisplayImage1(
                                      URL.createObjectURL(
                                        e.currentTarget.files[0]
                                      )
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Choose image"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.mobile_image}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <Image
                                width={200}
                                src={
                                  displayedImage1
                                    ? displayedImage1
                                    : values.mobile_image
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Domain
                            </label>
                            <div className="contact-name">
                              <Radio.Group
                                onChange={(e) => {
                                  setFieldValue("domain", e.target.value);
                                }}
                                value={values?.domain}
                              >
                                <Radio value={"chu"}>CHU</Radio>
                                <Radio value={"saudiVisa"}>Saudi Visa</Radio>
                              </Radio.Group>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.domain}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Banner Content
                            </label>
                            <div className="contact-name">
                              <Editor
                                apiKey={TINY_MCE_API_KEY}
                                value={values.banner_content}
                                init={TINY_MCE_EDITOR}
                                onEditorChange={(e) => {
                                  setFieldValue("banner_content", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.banner_content}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
